<template>
  <div class="guide-figure">
    <div class="guide-text">{{ error }}</div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getContext, getCurExternalChat } from '../../utils/nwechat'

export default {
  name: 'guide-figure',
  data() {
    return { error: '加载中...' }
  },
  mounted() {
    console.log('this.initEntry()')
    this.$nextTick(() => {
      this.initEntry()
    })
  },
  methods: {
    async initEntry() {
      Toast.loading({
        message: '寻路中...',
        duration: 0,
        forbidClick: true
      })
      this.error = '加载中...'
      console.log('#1')
      try {
        const entry = await getContext()
        console.log('#2')
        switch (entry) {
          case 'group_chat_tools': // 群聊 - 群看板
            // const id = await getCurExternalChat()
            // console.log('chatId:', id)
            this.$router.replace({
              name: 'community-detail-new'
              //   params: { id }
            })
            console.log('#3')
            break
          case 'single_chat_tools': // 单聊 - 客户画像
            this.$router.replace({ name: 'customer-portrait-tool' })
            console.log('#4')
            break
          default:
            this.error = '请从聊天工具栏进入！'
        }
      } catch (e) {
        console.log('#5')
        console.log(e)
        // this.error = '请重试！'
        this.error = '加载中...'
      }
      console.log('#6')
      Toast.clear()
    }
  }
}
</script>

<style lang="less" scoped>
.guide-text {
  padding: 24px 36px;
  text-align: center;
  color: #969799;
  font-size: 18px;
}
</style>
